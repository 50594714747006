<template>
	<div class="admin-container">
		<Head />
		<div class="banner-exc flex flex-ac flex-hc whole_000">{{$t('dhTxt')}}</div>
		<div class="admin-main admin_exp whole_fff">
			<div class="container">
				<div class="exp-top flex flex-ac flex-zBetween">
					<div class="exp-limit">{{$t('dhTxt2')}}</div>
					<div class="exp-rt">
						<el-button type="primary" class="btn" @click="tourl">
							<i class="el-icon-document-remove"></i>
							<p>{{$t('meun11')}}</p>
						</el-button>
					</div>
				</div>
				<el-form class="exp-form">
					<div class="exp-group">
						<div class="exp-group-hd flex flex-ac flex-zBetween">
							<div class="exp-label">{{$t('dhTxt3')}}</div>
							<div class="exp-sel flex flex-ac">
								<div class="exp-Avb">{{$t('dhplaceholder')}}</div>
								<div class="exp-money whole_000">{{balance}}</div>
								<div class="exp-tit whole-hui">{{fromUnit}}</div>
							</div>
						</div>
						<div class="exp-flex flex flex-ac">
							<el-input v-model="sellAmount" type="number" :placeholder="$t('placeholder2')+fromMin+'-'+fromMax"
								:max="fromMax" :min="fromMin" @input="changeBuyAmount">
								<!-- <template slot="suffix">{{$t('dhTxt5')}}</template> -->
							</el-input>
							<div class="exp-line"></div>
							<el-select v-model="value" placeholder="USDT" popper-class="accr_sel_pic"
								@change="fromChange">
								<el-option v-for="(item,index) in coins" :key="index" :label="item.coinUnit"
									:value="index">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="exp-icon flex flex-ac flex-hc"><i class="iconfont icon-switch"></i></div>
					<div class="exp-group">
						<div class="exp-group-hd flex flex-ac flex-zBetween">
							<div class="exp-label">{{$t('dhTxt4')}}</div>
						</div>
						<div class="exp-flex flex flex-ac">
							<el-input v-model="buyAmount" type="number" :placeholder="$t('placeholder2')" :max="toMax"
								:disabled="disabled" :min="toMin" @input="changeSellAmount">
							</el-input>
							<el-select v-model="value1" :placeholder="$t('placeholder3')" popper-class="accr_sel_pic"
								@change="toChange">
								<el-option v-for="(item,index) in toCoins" :key="index" :label="item.coinUnit"
									:value="index">
								</el-option>
							</el-select>
						</div>
					</div>
					<!-- <div class="exp-btn">
						<el-button class="btn">{{$t('dhTxt6')}}</el-button>
					</div> -->
					<div class="exp-btn" v-if="!isFlag">
						<el-button type="primary" class="btn btn_b" @click="isFlag = true">{{ $t('convertResult')}}</el-button>
					</div>
					<div class="exp_ft" v-show="isFlag">
						<div class="exp-result">
							<div class="exp-pane flex flex-ac flex-zBetween">
								<div class="exp-pane-t">{{$t('wth3111')}}</div>
								<div class="exp-pane-r">1 {{fromUnit}}={{price}} {{toUnit}}</div>
							</div>
							<div class="exp-pane flex flex-ac flex-zBetween">
								<div class="exp-pane-t">{{$t('willGet')}}</div>
								<div class="exp-pane-r exp-pane-num">{{totalPaymentShow}} {{toUnit}}</div>
							</div>
						</div>
						<div class="exp_tips">{{ $t('pleaseCommit') }}</div>
						<div class="exp-btn">
							<div class="exp_fl flex flex-ac">
								<el-button type="primary" class="btn btn_b btn_re" @click="back">{{ $t('returnText')}}</el-button>
								<el-button type="primary" class="btn btn_b btn_time" @click="submit">{{ $t('convert')}}</el-button>
							</div>
						</div>
					</div>
				</el-form>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		convertCoins,
		convertByUnit,
		convertPrice,
		assetWallet,
		convertExchange
	} from '@/api/api/user'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				sellAmount: '',
				buyAmount: '',
				coins: [],
				allCoins: [],
				fromUnit: '',
				fromMax: '',
				value: 0,
				value1: 0,
				fromMin: '',
				toMax: '',
				toMin: '',
				toCoins: [],
				toUnit: '',
				price: 0,
				isLogin: false,
				balance: 0,
				isFlag: false,
				disabled: false
			};
		},
		created() {
			this.isLogin = this.$store.state.isLogin
		},
		mounted() {
			this.getCoins()
		},
		watch: {
			// 'sellAmount': function(val) {
			// 	this.buyAmount = (val * this.price)
			// },
			// 'buyAmount': function(val) {
			// 	this.sellAmount = (val / this.price)
			// }
		},
		computed: {
			totalPaymentShow: function() {
				let fee = 0;
				for (let i = 0; i < this.allCoins.length; i++) {
					if (this.allCoins[i].coinUnit == this.toUnit) {
						let toCoin = this.allCoins[i];
						fee = this.sellAmount * this.price * toCoin.fee;
					}
				}
				return this.sellAmount * this.price - fee;
			}
		},
		methods: {
      changeBuyAmount(){
        this.buyAmount = (this.sellAmount * this.price)
      },
      changeSellAmount(){
        console.log(this.price)
        console.log(this.fromUnit)
        console.log(this.toUnit)
        this.sellAmount = (this.buyAmount / this.price)
      },
			getCoins() {
				if(this.isLogin){
					convertCoins().then(res => {
						if (res.code == 0) {
							this.allCoins = res.data;
							this.coins = res.data
							this.fromUnit = res.data[this.value].coinUnit
							this.fromMax = res.data[this.value].maxAmount
							this.fromMin = res.data[this.value].minAmount
							this.getCoinsByUnit()
						} else {
							this.$message.error(res.message)
						}
					})
				}else{
					this.$message.error(this.$t('loginFirst'))
					this.$router.push('/login')
				}
			},
			getCoinsByUnit() {
				convertByUnit({
					unit: this.fromUnit
				}).then(res => {
					if (res.code == 0) {
						this.toCoins = res.data
						this.toUnit = res.data[this.value1].coinUnit
						this.toMax = res.data[this.value1].maxAmount
						this.toMin = res.data[this.value1].minAmount
						this.getPrice();
						this.getAssets()
					} else {
						this.$message.error(res.message)
					}
				})
			},
			getPrice() {
				convertPrice({
					fromUnit: this.fromUnit,
					toUnit: this.toUnit
				}).then(res => {
					if (res.code == 0) {
						this.price = res.data
						this.disabled = false
            this.changeSellAmount()
					} else {
						this.$message.error(res.message)
						this.disabled = true
					}
				})
			},
			getAssets() {
				if (this.isLogin) {
					assetWallet({
						symbol: this.fromUnit
					}).then(res => {
						if (res.code == 0) {
							this.balance = res.data.balance
						} else {
							this.$message.error(res.message)
						}
					})
				} else {
					this.balance = 0;
				}
			},
			fromChange(value) {
				this.value = value
				this.getCoins()
        this.changeBuyAmount()
			},
			toChange(value) {
        console.log(value);
				this.toMax = this.toCoins[value].maxAmount
				this.toMin = this.toCoins[value].minAmount
        this.getCoins()
			},
			back() {
				this.isFlag = false
			},
			submit() {
        if( this.fromMin > this.sellAmount){
          this.$notify.error({
            title: this.$t("apiTxt2"),
            message: this.$t("minlimitamountfailed")
          });
          return;
        }
        if(this.fromMax < this.sellAmount ){
          this.$notify.error({
            title: this.$t("apiTxt2"),
            message: this.$t("maxlimitamountfailed")
          });
          return;
        }


				this.isFlag = false
				convertExchange({
					fromUnit: this.fromUnit,
					needAmount: this.sellAmount,
					toUnit: this.toUnit
				}).then(res => {
					if (res.code == 0) {
						this.$notify.success({
							title: this.$t("dhSuccess"),
							message: res.message
						});
						this.sellAmount = 0
						this.buyAmount = 0
						this.getAssets();
					} else {
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: res.message
						});
					}
				})
			},
			tourl(){
				this.$router.push('/spotOrder')
			}
		}
	}
</script>
